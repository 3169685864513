import mapLD from "lodash-es/map";
import splitLD from "lodash-es/split";
import snakeCaseLD from "lodash-es/snakeCase";
import { AppStateConstant } from "../constants/AppState.constant";
import { Router } from "@angular/router";
import { MOMENT_DATE_FORMAT } from "../config/MomentDateFormat.config";
import moment from "moment";
import { Buffer } from "buffer";
import { isEmpty } from "lodash-es";

export class UtilCommon {
  constructor(private router: Router) {}

  static isPhoneNumber(number) {
    if (!number) {
      return false;
    }
    if (number.toString()[0] !== "0") {
      return false;
    }
    if (number.toString().length !== 10 && number.toString().length !== 11) {
      return false;
    }
    if (!UtilCommon.isNumber(number)) {
      return false;
    }
    return true;
    // return /([\+84|84|0]+(3|5|7|8|9|1[2|6|8|9]))+([0-9]{8})\b/.test(number);
  }

  static getAppState(): any {
    return window[AppStateConstant.appState] ?? "";
  }

  static generateUUID(): string {
    try {
      let d: number = new Date().getTime();
      const uuid: any = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c: any): string {
          let r: any = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d / 16);
          return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
      return uuid;
    } catch (e) {}
  }

  static avoidEventBubble(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  static formatDate(
    value: any,
    format: string = MOMENT_DATE_FORMAT.ONLY_DATE
  ): string {
    if (value && (typeof value === "number" || typeof value === "string")) {
      const d: Date = new Date(value);
      const m: moment.Moment = moment(d);
      return m.format(format);
    } else if (value && value instanceof Date) {
      const m: moment.Moment = moment(value);
      return m.format(format);
    }
    return "";
  }

  static formatDateFullTime(
    value: any,
    format: string = MOMENT_DATE_FORMAT.FULL_TIME
  ): string {
    if (value && (typeof value === "number" || typeof value === "string")) {
      const d: Date = new Date(value);
      const m: moment.Moment = moment(d);
      return m.format(format);
    } else if (value && value instanceof Date) {
      const m: moment.Moment = moment(value);
      return m.format(format);
    }
    return "";
  }

  static formatDateTimeWithSecond(value: any): string {
    if (value && (typeof value === "number" || typeof value === "string")) {
      const d: Date = new Date(value);
      const m: moment.Moment = moment(d);
      return m.format(MOMENT_DATE_FORMAT.TIME_WITH_SECOND);
    } else if (value && value instanceof Date) {
      const m: moment.Moment = moment(value);
      return m.format(MOMENT_DATE_FORMAT.TIME_WITH_SECOND);
    }
    return "";
  }
  static formatDateTimeWithHourMinuteSecond(value: any): string {
    if (value && (typeof value === "number" || typeof value === "string")) {
      const d: Date = new Date(value);
      const m: moment.Moment = moment(d);
      return m.format(MOMENT_DATE_FORMAT.HOUR_MINUTE_SECOND);
    } else if (value && value instanceof Date) {
      const m: moment.Moment = moment(value);
      return m.format(MOMENT_DATE_FORMAT.HOUR_MINUTE_SECOND);
    }
    return "";
  }

  static formatDateJsonSchemaForm(value: any): string {
    if (value && (typeof value === "number" || typeof value === "string")) {
      value = new Date(value);
      const date =
        value.getDate() > 9 ? value.getDate() : "0" + value.getDate();
      const month =
        value.getMonth() + 1 > 9
          ? value.getMonth() + 1
          : "0" + (value.getMonth() + 1);
      return `${value.getFullYear()}-${month}-${date}`;
    } else if (value && value instanceof Date) {
      const date =
        value.getDate() > 9 ? value.getDate() : "0" + value.getDate();
      const month =
        value.getMonth() + 1 > 9
          ? value.getMonth() + 1
          : "0" + (value.getMonth() + 1);
      return `${value.getFullYear()}-${month}-${date}`;
    }
  }

  static getLinkDashboard(
    sanitizer: any,
    isToolbar: boolean,
    permissions: any
  ): any {
    const versionDashboard =
      window["MULTIPLE_APP"]["dashboard"] || new Date().getTime();
    let toolbar = "?v=" + versionDashboard;
    if (isToolbar) {
      toolbar += "&toolbar=true";
    }
    // console.log("dashboard link");
    sessionStorage.setItem("permissionsDashboard", JSON.stringify(permissions));
    // toolbar += '&permission=' + JSON.stringify(permissions);
    if (
      location.href.indexOf("gateway-dev") > -1 ||
      location.href.indexOf("localhost") > -1
    ) {
      return sanitizer.bypassSecurityTrustResourceUrl(
        window["HOSTNAME_DASHBOARD"]
      );
    } else {
      return sanitizer.bypassSecurityTrustResourceUrl(
        window["HOSTNAME_DASHBOARD"]
      );
    }
  }

  static formatDatePeriod(value: any): string {
    if (value && (typeof value === "number" || typeof value === "string")) {
      value = new Date(value);
      const month =
        value.getMonth() + 1 > 9
          ? value.getMonth() + 1
          : "0" + (value.getMonth() + 1);
      return `${month}/${value.getFullYear()}`;
    } else if (value && value instanceof Date) {
      const date =
        value.getDate() > 9 ? value.getDate() : "0" + value.getDate();
      const month =
        value.getMonth() + 1 > 9
          ? value.getMonth() + 1
          : "0" + (value.getMonth() + 1);
      return `${month}/${value.getFullYear()}`;
    }
  }

  static loadCSS(cssId: any, csstUrl: any): Promise<any> {
    return new Promise((resolve: any, reject: any) => {
      if (!document.getElementById(cssId)) {
        const head: any = document.getElementsByTagName("head")[0];
        const link: any = document.createElement("link");
        link.id = cssId;
        link.rel = "stylesheet";
        link.type = "text/css";
        link.href = csstUrl;
        link.media = "all";
        link.onload = function (): void {
          resolve(true);
        };
        link.onerror = function (): void {
          resolve(undefined);
        };
        head.appendChild(link);
      } else {
        resolve(true);
      }
    });
  }

  static async loadScriptAsync(objScript: any, scriptUrl: any) {
    return new Promise((resolve, reject) => {
      const me: any = this;

      if (objScript) {
        resolve(me);
        return;
      }

      const script: any = document.createElement("script");
      script.type = "text/javascript";
      script.src = scriptUrl;
      script.async = false;
      if (script.readyState) {
        // IE
        script.onreadystatechange = function (): void {
          if (
            script.readyState === "loaded" ||
            script.readyState === "complete"
          ) {
            script.onreadystatechange = undefined;
            resolve(me);
          }
        };
      } else {
        // Others
        script.onload = function (): void {
          resolve(me);
        };
      }
      document.head.appendChild(script);
    });
  }

  static loadScript(
    objScript: any,
    scriptUrl: any,
    callback: any,
    typeAsync: boolean = false
  ): void {
    const me: any = this;

    if (objScript) {
      callback(me);
      return;
    }

    const script: any = document.createElement("script");
    script.type = "text/javascript";
    script.src = scriptUrl;
    script.async = typeAsync;
    if (script.readyState) {
      // IE
      script.onreadystatechange = function (): void {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = undefined;
          callback(me);
        }
      };
    } else {
      // Others
      script.onload = function (): void {
        callback(me);
      };
    }
    document.head.appendChild(script);
  }

  static remove_unicode(str: string): string {
    if (!str) {
      return str;
    }
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ầ|Ẩ|Ậ|Ă|Ằ|Ấ|Ă|Ẳ|Ẵ/g, "A");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ẽ|Ể|Ễ/g, "E");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ổ|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ|Ố/g, "O");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/Ỷ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/Đ/g, "D");
    return str;
  }

  static getFilterModelSorts(filterModel: any, filter: string = ``): string {
    let filterString: string = filter;
    filterString = "";
    if (this.objectIsEmpty(filterModel)) {
      return "";
    }
    const keys: any = Object.keys(filterModel);
    keys.forEach((key: any) => {
      if (filterModel[key] !== null) {
        if (filterString.length === 0) {
          filterString = `{"field":"${key}","direction":"${filterModel[key]}"}`;
        } else {
          filterString += `,{"field":"${key}","direction":"${filterModel[key]}"}`;
        }
      }
    });
    return filterString;
  }

  static splitCharacterSpace(str: string): string {
    if (!str) {
      return "";
    }
    return str.replace(/\s/g, "");
  }

  static isArray(array: any, checkLength: boolean = true): boolean {
    if (!array) {
      return false;
    }
    if (checkLength) {
      if (Array.isArray(array) && array.length > 0) {
        return true;
      }
    } else if (Array.isArray(array)) {
      return true;
    }

    return false;
  }

  // get data header from server
  static getTokenFromLogin(resHeaders: any): void {
    if (resHeaders) {
      resHeaders.forEach((data: any, key: any) => {
        if (key.toString().toUpperCase() === "X-AUTH-TOKEN") {
          window["X-AUTH-TOKEN"] = data;
          localStorage.setItem("X-AUTH-TOKEN", data);
        } else if (key.toString().toUpperCase() === "X-AUTH-MESSAGE") {
          window["X-AUTH-MESSAGE"] = decodeURIComponent(data);
        }
      });
    }
  }

  static getToken(): string {
    return localStorage.getItem("tokenJWT") || "";
  }

  static handleUrl(href: string): string {
    let handleUrl: any = "",
      link: any;
    if (href.indexOf("#") > -1) {
      link = href.split("#");
      handleUrl = link[1].toString();
    }
    return handleUrl;
  }

  static handleDataSubmitServer(dataHandle: any): any {
    if (dataHandle.code === -1) {
      return -1;
    }
    return dataHandle.code;
  }

  static objectIsEmpty(map: any): boolean {
    for (const key in map) {
      if (map.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  static compareToDate(beforeDate: any, afterDate: any): number {
    // const date1: any = moment(beforeDate);
    // const date2: any = moment(afterDate);
    // if (date1 < date2) {
    //     return 1;
    // }
    // if (date1 > date2) {
    //     return -1;
    // }
    return 0;
  }

  static validateEmail(email: string): boolean {
    const regex: any = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(email);
  }

  static validatePassword(password: string): boolean {
    const regex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#+=_()<>?.,$%^&*])(?=.{8,})"
    );
    return regex.test(password);
  }

  static validateAccessCode(password: string): boolean {
    const regex = new RegExp(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/g
    );
    return regex.test(password);
  }

  static isNumber(value: any): boolean {
    // return !isNaN(str);
    // return typeof str === 'number';
    // if (typeof value != "string") return false;
    return !isNaN(parseFloat(value)) && isFinite(value);
  }

  static convertDateToIsoString(date: number): string {
    if (!date) {
      return new Date().toISOString();
    }
    return new Date(date).toISOString();
  }

  static isDate(date: any): boolean {
    return date && Object.prototype.toString.call(date) === "[object Date]";
  }

  static isValidDate(date: any): boolean {
    return !isNaN(date);
  }

  static async convertMenuTree(dataMenu: any) {
    const menuParent: any = [
      {
        id: "1",
        translate: "NAV.APPLICATIONS",
        type: "group",
        children: [],
      },
    ];
    // let lastIndex = 0;
    let language: any = localStorage.getItem("language") || "vi";
    let url =
      "assets/i18n/menu/" +
      this.buildUrlLoadMenu() +
      "/" +
      language +
      ".js?v=" +
      new Date().getTime();
    await this.loadScriptAsync(
      window["language_" + language],
      "assets/i18n/menu/" +
        this.buildUrlLoadMenu() +
        "/" +
        language +
        ".js?v=" +
        new Date().getTime()
    );
    language = window["language_" + language];
    const languageNAV = language.data.NAV;
    const menuFinal = [];
    for (const menu of dataMenu) {
      if (menu.menuName) {
        menu.title = languageNAV[menu.menuCode];
      }
      menu.id = menu.id.toString();
      menu.icon = "dashboard";
      const children: any[] = menu.children;
      if (this.isArray(children)) {
        menu.type = "collapsable";
        let child = null;
        for (let index = 0; index < children.length; index++) {
          child = children[index];
          let childrenLv2 = null;
          for (let childT of children[index].children) {
            childT = this.defineMenuChild(childT, languageNAV);
            childrenLv2 = childT.children;
            for (let childT2 of childrenLv2) {
              childT2 = this.defineMenuChild(childT2, languageNAV);
            }
          }
          children[index] = {
            title: languageNAV[child.menuCode],
            id: child.id.toString(),
            type: "collapsable",
            icon: this.getMenuIcon(children[index].menuCode),
            children: children[index].children,
            menuCode: children[index].menuCode,
          };
        }
      } else {
        menu.type = "basic";
        menu.link = menu.historyToken;
        menu.menuCode = menu.menuCode;
        menu.title = languageNAV[menu.menuCode];
      }
      menuFinal.push({
        id: menu.menuCode,
        title: languageNAV[menu.menuCode],
        type: "group",
        icon: "apps",
        children: menu.children,
        menuCode: menu.menuCode,
      });
    }
    menuParent[0].children = dataMenu;
    sessionStorage.setItem("menuFinal", JSON.stringify(menuFinal));
    localStorage.setItem("menuFinal", JSON.stringify(menuFinal));
    return menuFinal;
  }

  static getMenuIcon(menuCode) {
    switch (menuCode) {
      case "APPLICATION.1.1":
        return "mail";
      case "APPLICATION.1.2":
        return "person";
      case "APPLICATION.2.1":
        return "description";
      case "APPLICATION.2.2":
        return "share";
      case "APPLICATION.3.1":
        return "dashboard";
      case "APPLICATION.3.2":
        return "person";
      case "APPLICATION.3.3":
        return "settings_suggest";
      case "APPLICATION.4.1":
        return "article";
      case "APPLICATION.5.1":
        return "person";
      case "APPLICATION.5.3":
        return "notifications";
      case "APPLICATION.5.4":
        return "file_copy";
      case "APPLICATION.5.2":
        return "draw";
      case "APPLICATION.5.5":
        return "password";
    }
    return "dashboard";
  }

  static async convertLanguageMenu(dataMenu: any) {
    let language: any = localStorage.getItem("language") || "vi";
    const url =
      "assets/i18n/menu/" +
      this.buildUrlLoadMenu() +
      "/" +
      language +
      ".js?v=" +
      new Date().getTime();
    await this.loadScriptAsync(
      window["language_" + language],
      "assets/i18n/menu/" +
        this.buildUrlLoadMenu() +
        "/" +
        language +
        ".js?v=" +
        new Date().getTime()
    );
    language = window["language_" + language];
    const languageNAV = language.data.NAV;
    for (const menu of dataMenu) {
      // menu.title = languageNAV[menu.menuCode];
      menu.title = "MENU." + menu.title;
      const children = menu.children;
      for (const child of children) {
        // child.title = languageNAV[child.menuCode];
        child.title = "MENU." + child.title;
        for (const c of child.children) {
          c.title = "MENU." + c.title;
          // c.title = languageNAV[c.menuCode];
        }
      }
    }
    sessionStorage.setItem("menuFinal", JSON.stringify(dataMenu));
    localStorage.setItem("menuFinal", JSON.stringify(language));
    return dataMenu;
  }

  static buildUrlLoadMenu(): string {
    const appState = (<any>window)[AppStateConstant.appState];
    if (appState) {
      if (appState.moreInfo) {
        return "default";
      }
    }
    return "default";
  }

  private static defineMenuChild(childT: any, languageNAV: any): any {
    let link: string = "";
    link = UtilCommon.handleHistoryToken(childT.historyToken);
    if (!link) {
      link = childT.historyToken;
    }
    if (this.isArray(childT.children)) {
      childT.type = "collapsable";
    } else {
      childT.type = "basic";
      childT.link = link;
      childT.exactMatch = true;
    }
    childT.title = languageNAV[childT.menuCode];
    // childT.title = 'MENU.'+ childT.menuName;
    childT.menuCode = childT.menuCode;
    return childT;
  }

  public static handleHistoryToken(historyToken) {
    let link = "home";
    switch (historyToken) {
      case "contract-allocated":
        link = "manage/contract-allocated";
        break;
      case "budget-management":
        link = "manage/budget-management";
        break;
      case "search-contract":
        link = "manage/search-contract";
        break;
      case "search-kalapa":
        link = "manage/search-kalapa";
        break;
      case "manage/search-collections":
        link = "manage/hunt-contract";
        break;
      case "manage/geo-tracking":
        link = "manage/geo-tracking";
        break;
      case "contracts":
        link = "manage/contracts";
        break;
      case "contract-owl":
        link = "manage/contract-owl";
        break;
      case "checking-service":
        link = "general/checking-service";
        break;
      case "organization":
        link = "general/organization";
        break;
      case "contact":
        link = "general/contact";
        break;
      case "batch-upload":
        link = "manage/batch-upload";
        break;
      case "dashboard-overview":
        link = "report/dashboard-overview";
        break;
      case "user-report":
        link = "report/user-report";
        break;
      case "report/user-device":
        link = "report/user-device";
        break;
      case "report/sms-confirmation":
        link = "report/sms-confirmation";
        break;
      case "report/check-in":
        link = "report/check-in";
        break;
      case "report/travel-distance":
        link = "report/travel-distance";
        break;
      case "report/user-activity":
        link = "report/user-activity";
        break;
      case "report/user-management":
        link = "report/user-management";
        break;
      case "report/download-cloudwatch":
        link = "report/download-cloudwatch";
        break;
      case "report/planning":
        link = "report/planning";
        break;
      case "report/call-logs":
        link = "report/call-logs";
        break;
      case "report/payment-receipt":
        link = "report/payment-receipt";
        break;
      case "user-job":
        link = "report/user-job";
        break;
      case "unit":
        link = "general/units";
        break;
      case "employee":
        link = "general/employee";
        break;
      case "job-level":
        link = "general/job-level";
        break;
      case "job-title":
        link = "general/job-title";
        break;
      case "user":
        link = "general/user";
        break;
      case "service":
        link = "general/service";
        break;
      case "allocate-budget":
        link = "general/allocate-budget";
        break;
      case "supplier":
        link = "general/supplier";
        break;
      case "profile":
        link = "settings/profile";
        break;
      case "change-password":
        link = "settings/change-password";
        break;
      case "tenant-default":
        link = "settings/tenant-default";
        break;
      case "contract":
        link = "contract/my-cases";
        break;
      default:
    }
    return link;
  }

  static readBlobAsText(blob: Blob): Promise<string> {
    if (!blob) {
      return Promise.resolve("");
    }
    return new Promise((resolve, reject) => {
      const reader: any = new FileReader();
      reader.addEventListener("loadend", () => {
        resolve(reader.result);
      });
      reader.addEventListener("error", (e) => {
        reader.abort();
        reject(e);
      });
      reader.readAsText(blob);
    });
  }

  static downloadFile(fileName: string, blob: Blob): void {
    if (blob) {
      const anchorElement = document.createElement("a");
      anchorElement.download = fileName;
      anchorElement.href = window.URL.createObjectURL(blob);
      anchorElement.click();
    }
  }

  static isString(str: any): boolean {
    return typeof str === "string" || str instanceof String;
  }

  static toSnakeCase(str: string, separator = "."): string {
    return mapLD(splitLD(str, separator), snakeCaseLD).join(separator);
  }

  static encodeUriEachField(dataObj: any): string {
    let filterString = "";
    if (this.objectIsEmpty(dataObj)) {
      return filterString;
    }
    const keys: any = Object.keys(dataObj);
    let encodeText = "";
    keys.forEach((key: any) => {
      if (dataObj[key] !== null) {
        encodeText = encodeURI(dataObj[key]);
        filterString += `${key}=${encodeText}&`;
      }
    });
    if (filterString.lastIndexOf("&") > -1) {
      filterString = filterString.slice(0, -1);
    }
    return filterString;
  }

  static checkUsernameValid(): boolean {
    if (!window[AppStateConstant.appState]) {
      return true;
    }
    const username: string = localStorage.getItem(AppStateConstant.userName);
    const usernameTemp: string = (window[AppStateConstant.appState] as any)
      .username;
    if (!username || !usernameTemp) {
      return true;
    }
    if (
      username.toLowerCase().toString() !==
      usernameTemp.toLowerCase().toString()
    ) {
      return false;
    }
    return true;
  }

  static isNotNull(data): boolean {
    if (data == null || data === undefined) {
      return false;
    }
    if (data === "") {
      return false;
    }
    return true;
  }

  static checkDataFromServeComplete(data): boolean {
    if (data == null || data === undefined) {
      return false;
    }
    if (data === "") {
      return false;
    }
    return true;
  }

  static routingPageChild(
    url: string,
    activatedRoute: any = null,
    router: Router,
    ...data: any[]
  ): void {
    if (activatedRoute) {
      router.navigate([url, ...data], { relativeTo: activatedRoute });
    } else {
      router.navigate([url, ...data]);
    }
  }

  static checkISArray(data: any): boolean {
    if (!data) {
      return false;
    }
    if (Array.isArray(data) && data.length > 0) {
      return true;
    }
    return false;
  }

  static checkIsNotNull(data): boolean {
    if (data == null || data === undefined) {
      return false;
    }
    if (data === "") {
      return false;
    }
    return true;
  }

  static removeVietnameseTones(str): string {
    if (str) {
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
      str = str.replace(/đ/g, "d");
      str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
      str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
      str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
      str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
      str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
      str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
      str = str.replace(/Đ/g, "D");
      // Some system encode vietnamese combining accent as individual utf-8 characters
      // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
      str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
      str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
      // Remove extra spaces
      // Bỏ các khoảng trắng liền nhau
      str = str.replace(/ + /g, " ");
      str = str.trim();
      // Remove punctuations
      // Bỏ dấu câu, kí tự đặc biệt
      // str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g," ");
    }
    return str;
  }

  static removeSpecialCharacterForMermaid(str): string {
    if (str) {
      str = str.replace(/@|\(|\)|\[|\]|\{|\}|~|\||;|\"|\</g, "_");
      // Remove extra spaces
      // Bỏ các khoảng trắng liền nhau
      str = str.replace(/ + /g, " ");
      str = str.trim();
    }
    return str;
  }

  static validateCode(code: string) {
    if (code === "") return;
    const regex: any = /[^a-zA-Z0-9._-]/;
    return regex.test(code);
  }

  static validateYear(year: string) {
    if (year === "") return;
    const regex: any = /^(19|20)\d{2}$/;
    return regex.test(year);
  }

  static validateMonth(month: string) {
    if (month === "") return;
    const regex: any = /^(0?[1-9]|1[012])$/;
    return regex.test(month);
  }

  static validatePhoneNotRequireField(code: string) {
    if (code == "" || code == null) return true;
    // const regex :any = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    const regex: any = /^((\+84-?)|0)[0-9]{9}$/;
    return regex.test(code);
  }

  static validateEmailNotRequireField(email: string): boolean {
    if (email === "" || isEmpty(email)) return true;
    const regex: any = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(email);
  }

  static validateNIDNotRequireField(nid: string): boolean {
    if (nid == "" || nid == null) return true;
    const regex: any = /(^\d{9}$)|(^\d{12}$)/;
    return regex.test(nid);
  }

  static validateInputJustOnlyWhiteSpace(value: string): boolean {
    const regex: any = /^(\s+\S+\s*)*(?!\s).*$/;
    return regex.test(value);
  }

  static checkIsString(str: any): boolean {
    return typeof str === "string" || str instanceof String;
  }

  static getDateTime(value): Date {
    let timeMilis: Date;
    if (value) {
      timeMilis = UtilCommon.checkIsString(value) ? new Date(value) : value._d;
    }
    // return timeMilis;
    return moment(timeMilis).endOf("day").toDate();
  }

  static formatPartitionKey(value: any): string {
    if (value && (typeof value === "number" || typeof value === "string")) {
      value = new Date(value);
      const month =
        value.getMonth() + 1 > 9
          ? value.getMonth() + 1
          : "0" + (value.getMonth() + 1);
      return `${value.getFullYear()}${month}`;
    }
  }

  static validateSpecialCharacterExceptAccented(text: string): boolean {
    if (text === "" || text === null) return true;
    const regex: any = /^[^*|\.":<>[\]{}`\\()';@&$!,#%^/~=+_-]+$/;
    return regex.test(text);
  }

  static validateSpecialCharacterLessMore(text: string): boolean {
    if (text === "" || text === null) return true;
    const regex: any = /^[^<>]+$/;
    return regex.test(text);
  }

  static inputFieldOnlyNumber(event: any): boolean {
    const pattern = /[0-9\+\-\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      return true;
    }
    return false;
  }

  static convertJsonSchemaForm(data: any, readonly: any): any {
    let lstFields = data;
    let schema = {
      type: "object",
      properties: {},
      required: [],
    };
    let required = [];
    let layout = [];
    let lstLayoutObj = [];
    for (let obj of lstFields) {
      let key = obj.key;
      let title = obj.title;
      let enumF = obj.enum;
      let lstEnumValue = [];
      let lstEnum = [];
      if (enumF && enumF.length > 0) {
        for (let objE of enumF) {
          let enumValue = objE.value;
          let enumTitle = objE.title;
          lstEnumValue.push(enumValue);
          lstEnum.push({ value: enumValue, name: enumTitle });
        }
      }
      let type = obj.type;
      let layoutObj = {};
      if (type === "date") {
        schema.properties[key] = { type: "string" };
        layoutObj = { key: key, title: title, type: "date" };
      } else if (type === "text") {
        schema.properties[key] = { type: "string" };
        layoutObj = { key: key, title: title, type: "text" };
      } else if (type === "textarea") {
        schema.properties[key] = { type: "string" };
        layoutObj = { key: key, title: title, type: "textarea" };
      } else if (type === "number") {
        schema.properties[key] = { type: "number" };
        layoutObj = { key: key, title: title, type: "number" };
      } else if (type === "select") {
        schema.properties[key] = { type: "string", enum: lstEnumValue };
        layoutObj = {
          key: key,
          title: title,
          type: "select",
          titleMap: lstEnum,
        };
      } else if (type === "radios") {
        schema.properties[key] = { type: "string", enum: lstEnumValue };
        layoutObj = {
          key: key,
          title: title,
          type: "radios",
          titleMap: lstEnum,
        };
      } else if (type === "checkboxes") {
        schema.properties[key] = {
          type: "array",
          items: {
            type: "string",
            enum: lstEnumValue,
          },
        };
        layoutObj = {
          key: key,
          title: title,
          type: "checkboxes",
          titleMap: lstEnum,
        };
      }
      if (readonly) {
        layoutObj["readonly"] = true;
      }
      lstLayoutObj.push(layoutObj);
      let isRequired = obj.isRequired;
      if (isRequired && !readonly) {
        required.push(key);
      }
    }
    schema.required = required;

    for (let i = 0; i < lstLayoutObj.length; i = i + 2) {
      let left = lstLayoutObj[i];
      let right = i + 1 < lstLayoutObj.length ? lstLayoutObj[i + 1] : null;
      if (left && right) {
        let itemsLeft = [];
        itemsLeft.push(left);
        itemsLeft.push({
          type: "div",
          "flex-grow": "0",
          "flex-shrink": "0",
          "flex-basis": "0.5rem",
        });

        let itemsRight = [];
        itemsRight.push({
          type: "div",
          "flex-grow": "0",
          "flex-shrink": "0",
          "flex-basis": "0.5rem",
        });
        itemsRight.push(right);
        let items = [
          {
            type: "flex",
            "flex-flow": "row wrap",
            "flex-basis": "48%",
            "justify-content": "center",
            items: itemsLeft,
          },
          {
            type: "flex",
            "flex-flow": "row wrap",
            "flex-basis": "48%",
            "justify-content": "center",
            items: itemsRight,
          },
        ];
        layout.push({
          type: "flex",
          "flex-flow": "row wrap",
          "justify-content": "center",
          items: items,
        });
      } else {
        // layout.push(left);
        let itemsLeft = [];
        itemsLeft.push(left);
        itemsLeft.push({
          type: "div",
          "flex-grow": "0",
          "flex-shrink": "0",
          "flex-basis": "0.5rem",
        });
        let items = [
          {
            type: "flex",
            "flex-flow": "row wrap",
            "flex-basis": "48%",
            "justify-content": "center",
            items: itemsLeft,
          },
          {
            type: "flex",
            "flex-flow": "row wrap",
            "flex-basis": "48%",
            "justify-content": "center",
            items: [
              {
                type: "div",
                "flex-grow": "0",
                "flex-shrink": "0",
                "flex-basis": "0.5rem",
              },
            ],
          },
        ];
        layout.push({
          type: "flex",
          "flex-flow": "row wrap",
          "justify-content": "center",
          items: items,
        });
      }
    }

    return {
      schema: schema,
      layout: layout,
      data: {},
      options: {
        addSubmit: false,
      },
    };
  }

  static removeExtFile(fileName: string): string {
    if (fileName?.lastIndexOf(".") > -1) {
      return fileName?.substring(0, fileName?.lastIndexOf("."));
    }
    return fileName;
  }

  static formatPrice_VND(value: any, currency_VND = ""): any {
    if (!value) {
      return 0 + currency_VND;
    }
    if (isNaN(value)) {
      return value + currency_VND;
    }
    if (value === 0) {
      return value + currency_VND;
    }
    let sign = value == (value = Math.abs(value));
    value = Math.floor(value * 100 + 0.50000000001);
    value = Math.floor(value / 100).toString();
    for (
      let i = 0, length = Math.floor((value.length - (1 + i)) / 3);
      i < length;
      i++
    ) {
      value =
        value.substring(0, value.length - (4 * i + 3)) +
        "." +
        value.substring(value.length - (4 * i + 3));
    }
    return (sign ? "" : "-") + value + currency_VND;
  }

  static configNumberCurrency = {
    decimal: true,
    decimalLength: 3,
    decimalSeparator: ".",
    groupSeparator: ",",
  };

  static formatNumberCurrency(valueInput: any): any {
    if (!valueInput) {
      return 0;
    }
    valueInput = valueInput + "";
    if ((!valueInput && valueInput != 0) || valueInput.trim().length == 0) {
      return "";
    }

    let decimalValue = "";
    let decimalSeparator = "";
    let value = valueInput;

    if (valueInput.indexOf(this.configNumberCurrency.decimalSeparator) > -1) {
      let arr = valueInput.split(this.configNumberCurrency.decimalSeparator);
      value = arr[0];
      if (!value || value.length === 0) {
        value = "0";
      }
      decimalValue = arr[1];

      decimalSeparator = this.configNumberCurrency.decimalSeparator;
    }

    value = this.parseNumberCurrency(value);

    if (isNaN(value)) {
      return value + decimalSeparator + decimalValue;
    }
    if (value === 0) {
      return value + decimalSeparator + decimalValue;
    }
    let sign = value == (value = Math.abs(value));
    value = Math.floor(value * 100 + 0.50000000001);
    value = Math.floor(value / 100).toString();
    for (
      let i = 0, length = Math.floor((value.length - (1 + i)) / 3);
      i < length;
      i++
    ) {
      value =
        value.substring(0, value.length - (4 * i + 3)) +
        this.configNumberCurrency.groupSeparator +
        value.substring(value.length - (4 * i + 3));
    }
    value = value + decimalSeparator + decimalValue;
    return (sign ? "" : "-") + value;
  }

  static parseNumberCurrency(value: any): any {
    if ((!value && value != 0) || value.trim().length == 0) {
      return null;
    }
    let strValue: string = "";

    strValue = value.split(this.configNumberCurrency.groupSeparator).join("");
    strValue = strValue.replace(
      this.configNumberCurrency.decimalSeparator,
      "."
    );

    return strValue;
  }

  static dataUrlToFile(dataUrl: string, filename: string): File | undefined {
    const arr = dataUrl.split(",");
    if (arr.length < 2) {
      return undefined;
    }
    const mimeArr = arr[0].match(/:(.*?);/);
    if (!mimeArr || mimeArr.length < 2) {
      return undefined;
    }
    const mime = mimeArr[1];
    const buff = Buffer.from(arr[1], "base64");
    return new File([buff], filename, { type: mime });
  }

  static replaceCharacterSpecial(str, character) {
    return str.toString().replaceAll(character, "");
  }

  static convertStringToHex(str: string): string {
    let result = "";
    for (let i = 0; i < str.length; i++) {
      result += str.charCodeAt(i).toString(16);
    }
    return result;
  }

  static getRandomString(): string {
    const letters = "0123456789abcdefghijklmnopqrstuyqw".split("");
    let _color = "";
    for (let i = 0; i < 15; i++) {
      _color += letters[Math.floor(Math.random() * 32)];
    }
    return _color;
  }

  static convertHexToString(hex: string): string {
    let str = "",
      v;
    for (let i = 0; i <= hex.length; i += 2) {
      if (i < 2) {
        v = parseInt(hex.substring(0), 16);
      } else {
        v = parseInt(hex.substring(i, i - 2), 16);
      }
      if (v) {
        str += String.fromCharCode(v);
      }
    }
    return str;
  }

  static getJWTToken(type: string, token: any): string {
    // this._utils.getJWTToken('set', password);
    // token = 'jf6osjj10kit9l0.41626340313233343536.5mmcrjmj437ljno';
    if (type === "set") {
      const first = this.getRandomString(),
        last = this.getRandomString();
      token = first + "." + this.convertStringToHex(token) + "." + last;
    } else {
      let tempToken = token.split(".");
      if (Array.isArray(tempToken) && tempToken.length > 2) {
        tempToken = tempToken[1];
      }
      token = this.convertHexToString(tempToken);
    }
    return token;
  }

  static setRootPage(router, pageDefault, callback): void {
    router.navigate([pageDefault], { replaceUrl: true }).then(() => {
      if (callback) {
        callback.call();
      }
    });
  }

  static removeCheckTokenPage(hash): boolean {
    if (
      [
        "accept-member",
        "not-found",
        "forgot-password",
        "sign-up",
        "sign-in",
        "confirm-sign",
        "sign-document",
        "resetpass",
        "confirm-sign-social",
        "reset-password",
        "unlock-session",
      ].findIndex((h) => hash.indexOf(h) >= 0) >= 0
    ) {
      return true;
    }
    return false;
  }

  static isCRMApp() {
    if (window.location.href.indexOf("crm") > -1) {
      return true;
    }
    return false;
  }

  static parseNumberMoney(moneyFormatString: string): number {
    if (!moneyFormatString) return 0;
    if (moneyFormatString && moneyFormatString.includes(".")) {
      return parseInt(moneyFormatString.split(".").join(""), 10);
    } else {
      return parseInt(moneyFormatString, 10);
    }
  }

  static formatNumberCurrencyVND(moneyFormatNumber: number): string {
    const formatCurrency = moneyFormatNumber.toLocaleString("it-IT", {
      style: "currency",
      currency: "VND",
    });
    return formatCurrency;
  }

  static genTenantCode(): string {
    // let ATHENA = {
    //     'https://idms-uat.a4b.vn': true,
    //     'https://ilms-uat.a4b.vn': true,
    //     'https://icrm-uat.a4b.vn': true,
    //     'https://hunt-uat.a4b.vn': true,
    //     'https://athenaomni.athenafs.io': true,
    //     'https://athenahunt.athenafs.io': true,
    //     'https://athenashield.athenafs.io': true,
    //     'https://athenacastle.athenafs.io': true,
    // }
    // let OTHER = {
    //     'https://omniuat.athenafs.io': true,
    //     'https://huntuat.athenafs.io': true,
    //     'https://shielduat.athenafs.io': true,
    //     'https://castleuat.athenafs.io': true,
    // }
    // const origin = window.location.origin;
    // if (ATHENA[origin]) {
    //     return 'GALAXY';
    // }
    // if (OTHER[origin]) {
    //     return 'GALAXY_STAGING';
    // }
    return "BROTHER";
  }

  static genSettingsApp() {
    let LOGO_APP: string = "assets/images/logo/logo.svg";
    // if (window.location.href.indexOf('uat.athenafs.io') > -1) {
    //     LOGO_APP = 'assets/images/logo/logo_staging.svg';
    // } else {
    //     LOGO_APP = 'assets/images/logo/logo.svg';
    // }
    return {
      LOGO_APP: LOGO_APP,
    };
  }

  public static isGreaterWithMonth(
    startDate: moment.Moment,
    endDate: moment.Moment,
    month: number
  ) {
    const diffInMonths = endDate.diff(startDate, "months");
    return diffInMonths > month;
  }

  static formatNumber(valueInput: any): any {
    if (!valueInput) {
      return 0;
    }
    valueInput = valueInput + "";
    if ((!valueInput && valueInput != 0) || valueInput.trim().length == 0) {
      return "";
    }

    let value = valueInput;

    let sign = value == (value = Math.abs(value));
    value = Math.floor(value * 100 + 0.50000000001);
    value = Math.floor(value / 100).toString();
    for (
      let i = 0, length = Math.floor((value.length - (1 + i)) / 3);
      i < length;
      i++
    ) {
      value =
        value.substring(0, value.length - (4 * i + 3)) +
        "." +
        value.substring(value.length - (4 * i + 3));
    }
    return (sign ? "" : "-") + value;
  }

  // Utility function to add days to a date
  static addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
}
